import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Route, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { dashboardApi } from '@reducers/login';
import Footer from '@components/common/Footer';
import cn from 'classnames';
import SideMenu from '@components/common/SideMenu/navbar';
import PaymentPage from '@containers/payemtPage';
import PaymentProcessing from '@containers/PaymentProcessing';
import { toast } from 'react-toastify';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isUserBaseDataLoaded: false,
      is_fee_received: false,
      is_fee_processed: false,
      hasError: false,
      retryCount: 0,
    };
  }

  componentDidMount() {
    this.checkAuthentication();
    this.getUserBaseData();
  }

  async getUserBaseData() {
    try {
      console.log(`isUserBaseDataLoaded: ${this.state.isUserBaseDataLoaded}`);

      const timeout = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Request timed out')), 15000)
      );

      await Promise.race([this.props.dashboardApi(), timeout]);

      console.log('User data from PrivateRoute', this.props, this.state);
      this.setState({
        isUserBaseDataLoaded: true,
        is_fee_received: this.props.userBaseData.is_fee_received,
        is_fee_processed: this.props.userBaseData.is_fee_processed,
        hasError: false,
        retryCount: 0,
      });
    } catch (error) {
      console.error(error);
      this.handleRetry();
    }
  }

  handleRetry() {
    const { retryCount } = this.state;
    const maxRetries = 3;
    
    if (retryCount < maxRetries) {
      const retryDelay = Math.pow(2, retryCount) * 1000; // Exponential backoff

      toast.warn(`Network issue detected. Retrying in ${retryDelay / 1000} seconds...`);

      setTimeout(() => {
        this.setState({ retryCount: retryCount + 1 });
        this.getUserBaseData();
      }, retryDelay);
    } else {
      this.setState({ hasError: true });
      toast.error('Failed to load user data after multiple attempts. Please try again later.');
      this.props.history.push("/error");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authed !== this.props.authed) {
      this.checkAuthentication();
    }
  }

  checkAuthentication() {
    const token = window.localStorage.getItem('access_token');
    const isAuthenticated = token && token !== 'undefined' && token !== null && token !== '';
    this.setState({ isAuthenticated });
  }

  render() {
    const { component: Component, authed, path, ...rest } = this.props;
    const { isAuthenticated, is_fee_received, is_fee_processed, isUserBaseDataLoaded, hasError } = this.state;

    if (hasError) {
      return <div>Failed to load data. Please try again later.</div>;
    }

    if (!isUserBaseDataLoaded) {
      return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    //IF redirected from cf payment gateway
    if(path && path.endsWith("request-for-payment-review")){
      return <Component {...this.props} />;
    }
    

    //To PAYMENT PAGE
    if (!is_fee_received) {
      return (
        <>
          <SideMenu {...this.props} />
          <PaymentPage {...this.props} />
        </>
      );
    }

    if (!is_fee_processed) {
      return (
        <>
          <SideMenu {...this.props} />
          <PaymentProcessing {...this.props} />
        </>
      );
    }

    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <>
              <SideMenu {...this.props} />
              <Component {...this.props} />
            </>
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userBaseData: state.login.dashboardData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dashboardApi,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
);
