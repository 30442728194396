/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { Modal, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import {
  DropdownToggle, DropdownItem, UncontrolledDropdown, DropdownMenu
} from 'reactstrap';
import axios from 'axios';
import src from '../../../assets/images/gyan.png';
import EN from '../../../localization/EN';

import { SidebarData } from './SlidebarData';
import './Navbar.css';

export default function Navbar(props) {
  const [sidebar, setSidebar] = useState(false);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(props.userBaseData.email_verified);
  const [role, setRole] = useState('user');

  const [activePath, setActivePath] = useState(props.location.pathname);

  const showSidebar = () => setSidebar(!sidebar);
  const boxRef = useRef(null);

  useEffect(() => {

    function handleClickOutside(event) {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setSidebar(false);
      } else {
        setSidebar(true);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [boxRef]);

  useEffect(()=>{
    if (props && props.userBaseData && props.userBaseData.sponsor_id === 'GYAN_ADM00000001') {
      setRole('admin');
    } else if (props && props.userBaseData && props.userBaseData.tutorial_enabled) {
      setRole('tutorialOwner');
    } else {
      setRole('user');
    }
  }, props.userBaseData)

  useEffect(() => {
    const unlisten = props.history.listen((location) => {
      setActivePath(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, [props.history]);

  
  const emailId = props.userBaseData.email_id;
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setOtpSent(false);
    setEmail('');
    setOtp('');
    setMessage('');
  };
  const token = window.localStorage.getItem('access_token');
  const sendOtp = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://api.gyanalaya.net/api/users/otp/mail', { email },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in headers
        },
      }
      );
      if (response.data.status === 'success') {
        setOtpSent(true);
        setMessage(response.data.message);
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setMessage('Error sending OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to verify the OTP
  const verifyOtp = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://api.gyanalaya.net/api/users/otp/verify', { otp },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in headers
        },
      }
      );
      if (response.data.status === "verified") {
        setMessage(response.data.message);
        alert(response.data.message);
        handleClose();
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setMessage('Error verifying OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const filteredSidebarData = SidebarData.filter(item => item.roles.includes(role));
  return (
    <>
      <IconContext.Provider value={cn('iconColor')}>
        <div className="navbar">
          <div className="row">
            <div ref={boxRef} className="col-auto">
              <Link to="#" className="menu-bars">
                <FaIcons.FaBars onClick={showSidebar} />
              </Link>
            </div>
              <div className="col-auto">
              <a className="navbar-brand" href="#">
                <img
                  src={src}
                  alt="fullimage"
                  className="img-responsive cursor-default main-img mr-10 ml-10 nmt-5"
                />
                {!isEmailVerified && (
                <a href="#" onClick={handleShow} style={{ color: 'blue', cursor: 'pointer' }}>
                  Verify Email
                </a>
                )}
              </a>
            </div>
          </div>
          <UncontrolledDropdown className={cn('dropdown cursor-pointer')}>
            <DropdownToggle tag="span">
              <div className="headerText">
                <i className={cn('fa fa-user-circle')} />
                <span className="span">
                  {props.userBaseData.name}
                </span>
                <i className={cn('fa fa-angle-down ml-10')} />
              </div>
            </DropdownToggle>
            <DropdownMenu className="mt-10">             
              <DropdownItem>
                <a className="nav-link text-secondary test2" href="/reset_password">
                  <i className={cn('fa fa-sign-out pr-2')} aria-hidden="true" />
                  {EN.RESET_PASSWORD}
                </a>
              </DropdownItem>
              <DropdownItem>
                <a className="nav-link text-secondary test2" style={{textAlign:'left'}} href="/logout">
                  <i className={cn('fa fa-sign-out pr-2')} aria-hidden="true" />
                  {EN.LOGOUT}
                </a>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <nav ref={boxRef} className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className="nav-menu-items" onClick={showSidebar} onKeyDown={showSidebar}>
            {/* <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li> */}

            {/* {filteredSidebarData.filter(item => item.roles.includes(role)).map((item) => {
              return (
                <li key={item} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span className="pl-2">
                      {item.title}
                    </span>
                  </Link>
                </li>
              );
            })} */}
                  {filteredSidebarData.map((item, index) => (
                    <li
                      key={index}
                      className={item.cName + (activePath === item.path ? ' active' : '')}
                    >
                      <Link to={item.path}>
                        {item.icon}
                        <span className="pl-2">{item.title}</span>
                      </Link>
                    </li>
                  ))}

          </ul>
        </nav>
      </IconContext.Provider>
      <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Verify Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!otpSent ? (
            <>
              <Form.Group controlId="formEmail">
                <Form.Label>Email will be sent to below mail id</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={emailId}
                  readOnly
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                onClick={sendOtp}
                disabled={email || loading}
              >
                {loading ? 'Sending...' : 'Send OTP'}
              </Button>
            </>
          ) : (
            <>
              <Form.Group controlId="formOtp">
                <Form.Label>Enter the OTP sent to {email}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                onClick={verifyOtp}
                disabled={!otp || loading}
              >
                {loading ? 'Verifying...' : 'Verify'}
              </Button>
            </>
          )}
          {message && <p style={{ marginTop: '10px', color: 'red' }}>{message}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    </>
  );
}
