import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { dashboardApi } from '@reducers/login';
import { checkPayment } from '../../helpers/Utility.js';
// import { CONSTANTS } from '@constants';
// import cn from 'classnames';

class PaymentProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minutes: 5,
      seconds: 0,
    };
  }

  componentDidMount() {
    var timeToRun = 60 * 4; //4 mins
    // var timeToRun = 6; //6 seconds for testing
    this.startTimer(timeToRun);
  }

  startTimer = (duration) => {
    var timer = duration, minutes, seconds;
    var clk = setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      this.setState({ minutes: minutes, seconds: seconds });

      if (--timer < 0) {
        clearInterval(clk);
        window.location.href = '/dashboard';
      }
    }, 1000);
  };

  render() {
    return (
      <div>
        'We have received your payment, your one time account activation is started and is in progress!!!<br/> It can take up to 4 mins to complete.
         Kindly, relogin or we will redirect to your dashboard automatically in '
        {this.state.minutes} min: {this.state.seconds} sec.
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // dashboardData: state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // dashboardApi,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentProcessing));
