import { details } from "react-dom-factories";

export const GET_TUTORIAL_REQUESTED = 'tutorial/GET_TUTORIAL_REQUESTED';
export const GET_TUTORIAL_SUCCESS = 'tutorial/GET_TUTORIAL_SUCCESS';
export const GET_TUTORIAL_FAILURE = 'tutorial/GET_TUTORIAL_FAILURE';

export const GET_TUTORIAL_VIDEOS_REQUESTED = 'tutorial/GET_TUTORIAL_VIDEOS_REQUESTED';
export const GET_TUTORIAL_VIDEOS_SUCCESS = 'tutorial/GET_TUTORIAL_VIDEOS_SUCCESS';
export const GET_TUTORIAL_VIDEOS_FAILURE = 'tutorial/GET_TUTORIAL_VIDEOS_FAILURE';

export const ADD_TUTORIAL_REQUESTED = 'tutorial/ADD_TUTORIAL_REQUESTED';
export const ADD_TUTORIAL_SUCCESS = 'tutorial/ADD_TUTORIAL_SUCCESS';
export const ADD_TUTORIAL_FAILURE = 'tutorial/ADD_TUTORIAL_FAILURE';

export const GET_TUTORIAL_COURSE_REQUESTED = 'tutorial/GET_TUTORIAL_COURSE_REQUESTED';
export const GET_TUTORIAL_COURSE_SUCCESS = 'tutorial/GET_TUTORIAL_COURSE_SUCCESS';
export const GET_TUTORIAL_COURSE_FAILURE = 'tutorial/GET_TUTORIAL_COURSE_FAILURE';

export const GET_TUTORIAL_VIDEO_REQUESTED = 'tutorial/GET_TUTORIAL_VIDEO_REQUESTED';
export const GET_TUTORIAL_VIDEO_SUCCESS = 'tutorial/GET_TUTORIAL_VIDEO_SUCCESS';
export const GET_TUTORIAL_VIDEO_FAILURE = 'tutorial/GET_TUTORIAL_VIDEO_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  tutorialData: [],
  addTutorialMsg: '',
  addTutorialError: '',
  courses_details : [],
  error_details: null,
  video_details:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    
    case GET_TUTORIAL_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TUTORIAL_SUCCESS:
    {
        console.log('3333333333333222222222222222222', action.result);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        tutorialData: action && action.result && action.result,
        code: '',
        status: '200'
      };
    }
    case GET_TUTORIAL_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        code: action && action.result && action.code,
        msg: action && action.result && action.msg,
        status: action && action.result && action.result.status
      };
    }
    case GET_TUTORIAL_VIDEOS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TUTORIAL_VIDEOS_SUCCESS:
    {
        console.log('3333333333333222222222222222222', action.result);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        tutorialVideos: action && action.result && action.result,
        code: '',
        status: '200'
      };
    }
    case GET_TUTORIAL_VIDEOS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        code: action && action.result && action.code,
        msg: action && action.result && action.msg,
        status: action && action.result && action.result.status
      };
    }
    case ADD_TUTORIAL_REQUESTED:
      {
        return {
          ...state,
          loading: true,
          loaded: false,
        };
      }
      case ADD_TUTORIAL_SUCCESS:
      {
        console.log('successsss', action.result);
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          status: '200',
          addTutorialMsg: action.result && action.result.message
        };
      }
      case ADD_TUTORIAL_FAILURE:
      {
        console.log('3333333333333333333333333333', action.error);
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true,
          status: action && action.error  && action.error.data && action.error.data.status,
          addTutorialError: action && action.error && action.error.data && action.error.data.message,
        };
      }
      case GET_TUTORIAL_COURSE_REQUESTED:
      {
        return {
          ...state,
          loading: true,
          loaded: false,
        };
      }
      case GET_TUTORIAL_COURSE_SUCCESS:
      {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          status: '200',
          courses_details: action.result && action.result
        };
      }
      case GET_TUTORIAL_COURSE_FAILURE:
      {
        console.log('3333333333333333333333333333', action.error);
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true,
          error_details: action && action.error
        };
      }
      case GET_TUTORIAL_VIDEO_REQUESTED:
      {
        return {
          ...state,
          loading: true,
          loaded: false,
        };
      }
      case GET_TUTORIAL_VIDEO_SUCCESS:
      {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          status: '200',
          video_details: action.result && action.result
        };
      }
      case GET_TUTORIAL_VIDEO_FAILURE:
      {
        console.log('3333333333333333333333333333', action.error);
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true,
          error_details: action && action.error
        };
      }
    default:
      return state;
  }
};

export const getTutorial = (tutorialOwnerUserid) => {
    console.log('aaaaaaaaaaaaaaaaaaaaaaa0000000000000000000ssssssssssssssss');
  return {
    types: [GET_TUTORIAL_REQUESTED, GET_TUTORIAL_SUCCESS, GET_TUTORIAL_FAILURE],
    promise: client => client.get('getTutorial', {
      params : {'ref' : tutorialOwnerUserid}
    })
  };
};

export const getTutorialVideos = (courseid) => {
return {
  types: [GET_TUTORIAL_VIDEOS_REQUESTED, GET_TUTORIAL_VIDEOS_SUCCESS, GET_TUTORIAL_VIDEOS_FAILURE],
  promise: client => client.get('getVideos', {
    params: { 'course-id' : courseid }
  })
};
};

export const addTutorial = (data) => {
  console.log('dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', data);
  return {
    types: [ADD_TUTORIAL_REQUESTED, ADD_TUTORIAL_SUCCESS, ADD_TUTORIAL_FAILURE],
    promise: client => client.post('addTutorial', {
      data
    })
  };
};

export const getOrgCourse = () => {
  return {
    types: [GET_TUTORIAL_COURSE_REQUESTED, GET_TUTORIAL_COURSE_SUCCESS, GET_TUTORIAL_COURSE_FAILURE],
    promise: client => client.get('viewOrgCourses', {
      
    })
  };
};

export const getOrgVideos = () => {
  return {
    types: [GET_TUTORIAL_VIDEO_REQUESTED, GET_TUTORIAL_VIDEO_SUCCESS, GET_TUTORIAL_VIDEO_FAILURE],
    promise: client => client.get('viewOrgVideos', {
      
    })
  };
};

export const createCourse = (data) => {
  return {
    types: [GET_TUTORIAL_COURSE_REQUESTED, GET_TUTORIAL_COURSE_SUCCESS, GET_TUTORIAL_COURSE_FAILURE],
    promise: client => client.post('createCourse', {
      data
    })
  };
};

export const createVideo = (data) => {
  
  return {
    types: [GET_TUTORIAL_VIDEO_REQUESTED, GET_TUTORIAL_VIDEO_SUCCESS, GET_TUTORIAL_VIDEO_FAILURE],
    promise: client => client.post('createVideo', {
      data
    })
  };
};