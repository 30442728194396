/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import * as AiIcons from 'react-icons/ai';
import { RiSettings3Fill,RiVideoAddFill,RiBankFill,RiFileListLine,RiVideoUploadLine,RiTeamLine } from 'react-icons/ri';
import { GiBattleAxe } from 'react-icons/gi';
import { FaHistory, FaUserCheck, FaUserAltSlash } from 'react-icons/fa';

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <AiIcons.AiFillHome/>,
    cName: 'nav-text',
    roles: ["admin", "user","tutorialOwner"]
  },
  {
    title: 'Team View',
    path: '/team_view',
    icon: <AiIcons.AiOutlineTeam />,
    cName: 'nav-text',
    roles: ["admin", "user", "tutorialOwner"]
  },
  {
    title: 'Transaction',
    path: '/transaction_page',
    icon: <RiFileListLine />,
    cName: 'nav-text',
    roles: ["admin", "user","tutorialOwner"]
  },
  {
    title: 'Beneficiary',
    path: '/add_beneficiary',
    icon: <RiBankFill />,
    cName: 'nav-text',
    roles: ["admin", "user","tutorialOwner"]
  },
  {
    title: 'Add Tutorial',
    path: '/add_tutorial',
    icon: <RiTeamLine />,
    cName: 'nav-text',
    roles: ["admin"]
  },
  {
    title: 'Manage Course',
    path: '/manage_course',
    icon: <RiVideoAddFill />,
    cName: 'nav-text',
    roles: ["admin", "tutorialOwner"]
  }
  // ,
  // {
  //   title: 'Tournaments',
  //   path: '/tournaments',
  //   icon: <AiIcons.AiFillTrophy />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Battles',
  //   path: '/battles',
  //   icon: <GiBattleAxe />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Questions',
  //   path: '/questions',
  //   icon: <AiIcons.AiFillQuestionCircle />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Content Upload',
  //   path: '/upload/content',
  //   icon: <AiIcons.AiFillDatabase />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Config Upload',
  //   path: '/upload/config',
  //   icon: <RiSettings3Fill />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Activity',
  //   path: '/activity',
  //   icon: <FaHistory />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'User KYC',
  //   path: '/user/kyc',
  //   icon: <FaUserCheck />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Block User',
  //   path: '/block/user',
  //   icon: <FaUserAltSlash />,
  //   cName: 'nav-text'
  // }
];
